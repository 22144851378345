import * as React from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, Typography } from "@mui/material"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Layout } from "../components/Layout"
import { SEO } from "../components/Seo"

type DataProps = {
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

const AboutPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <Layout title="about me" image={data.image.childImageSharp.gatsbyImageData}>
      <Typography variant="h1"></Typography>
      <Typography>
        I am an Italian musician living in The Netherlands, making chill lo-fi tunes that you can enjoy when taking a
        break from this chaotic world.
      </Typography>
      <Typography>
        Born and raised in a small town in northern Italy, I discovered my passion for music at the age of 14, playing
        Guitar Hero III. After just one month I bought my first electric guitar, that&apos;s when the magic began.
      </Typography>
      <Typography>
        What got me what this <i>power</i> of making sounds with my own hands and the emotions that such sounds
        translated into. I quite soon started craving for more and more powerful sounds, so I quickly got into metal,
        started few bands and then also got in music production, for both electronic and analog music.
      </Typography>
      <Typography>
        Music can be a <i>magnificent way of expressing emotions</i>, and I got caught on this idea that a simple
        vibration in the air can produce such a big spectrum of <i>feelings</i>.
      </Typography>
      <Typography>
        I currently live in The Netherlands, where I settled for much softer and chillier sounds, which you can listen
        to on any streaming platform.
      </Typography>
      <Button variant="contained" component={GatsbyLink} to="/" startIcon={<ArrowBackIcon />}>
        Back to home
      </Button>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    image: file(relativePath: { eq: "pauliev.jpeg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, height: 128, width: 128)
      }
    }
  }
`

export const Head = () => <SEO title="About paulie.v" />
